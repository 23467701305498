@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

.Modal_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.menumodal {
  display: flex;
  background-color: #ffd305;
  color: #000;
  width: 400px;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  font-family: "Montserrat";
  font-weight: 400;
}
.colmenumodal1 {
  width: 100%;
  text-align: center;
}
.menumodal:hover {
  cursor: pointer;
}

.btnmodalactive {
  background-color: #ffff;
}

.menumodal p {
  padding: 0.5em;
  margin: 0;
}

.ModalContainer {
  background-color: #ffd305;
  height: 400px;
  position: relative;
  border-radius: 0px 0px 5px 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 400px;
}

.subhead {
  color: #4d4d4d;
}
.inputemailwv {
  display: flex;
  flex-direction: column;
  text-align: flex-start;
}
.emailbox {
  text-align: left;
}
.correowv {
  font-size: 11px;
}
.passwordwv {
  padding: 15px 0 0 0;
  font-size: 11px;
}
.emailwvbox {
  border: none;
  padding: 2.5px 0px;
}
.passbox {
  padding: 2.5px 0px;
  margin: 0 0 5px 0;
  border: none;
}
.btningresarmodal {
  background-color: #244cd3;
  margin: 20px 2px 2px 0;
  padding: 7px 0;
  border-radius: 18px;

  color: white;
  border: none;
  letter-spacing: 1.25px;
  width: 100%;
}
.buttonlogin-forgetpass {
  text-align: center;
}
.message_error{
  color: red;
  padding-top: .5em;
}

.forgetpassword {
  margin: 35px 0 0 0;
  font-size: 11px;
}
.closebtn {
  position: absolute;
  top: -70px;
  right: -40px;
  width: 30px;
  height: 30px;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #ffffff;
  border-radius: 26px;
  border-color: #ffffff;
  font-size: 1;
  text-transform: uppercase;
  font-weight: bolder;
}

.ModalSigninContainer {
  background-color: #ffd305;
  height: 400px;
  position: relative;
  border-radius: 0px 0px 5px 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 400px;
}

.subhead {
  color: #4d4d4d;
}
.inputemailwv {
  display: flex;
  flex-direction: column;
  text-align: flex-start;
}
.emailbox {
  text-align: left;
}
.correosigninwv {
  font-size: 11px;
  margin-top: 15px;
}
.passwordwv {
  padding: 15px 0 0 0;
  font-size: 11px;
}
.emailwvbox {
  border: none;
  padding: 2.5px 0px;
}
.passbox {
  padding: 2.5px 0px;
  margin: 0 0 5px 0;
  border: none;
}
.btnregistrartemodal {
  background-color: #ffd305;
  margin: 20px 2px 2px 0;
  padding: 7px 0;
  border-radius: 18px;
  color: black;
  font-weight: bolder;
  font-family: Montserrat;
  border: none;
  letter-spacing: 1.25px;
  width: 100%;
}
.buttonsignin {
  text-align: center;
}
.forgetpassword {
  margin: 35px 0 0 0;
  font-size: 11px;
}
@media (max-width: 860px) {
  .menumodal {
    width: 50%;
  }

  .ModalContainer {
    width: 50%;
    height: 450px;
  }

  .ModalSigninContainer {
    width: 50%;
    height: 450px;
  }
}
@media (max-width: 600px) {
  .menumodal {
    width: 95%;
  }

  .ModalContainer {
    width: 95%;
    height: 500px;
  }

  .ModalSigninContainer {
    width: 95%;
    height: 500px;
  }
}

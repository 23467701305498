@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

.main-grid {
  position: relative;
  left: 4%;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 80vh;
  width: 100%;
}

.grid {
  display: grid;
  grid-gap: 0.25em;
  width: 100%;
  padding: 1em;
  min-height: 650px;
}

.grid-item {
  padding: 1em;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.grid-item::-webkit-scrollbar {
  -webkit-appearance: none;
}
.grid-item::-webkit-scrollbar:vertical {
  width: 5px;
}

.grid-item::-webkit-scrollbar-button:increment,
.grid-item::-webkit-scrollbar-button {
  display: none;
}

.grid-item::-webkit-scrollbar-thumb {
  background-color: #979797;
  border-radius: 10px;
  border: none;
}

.grid-item::-webkit-scrollbar-track {
  border-radius: 10px;
}

.grid-item:first-child {
  background-color: #ffeecc;
}

.grid-item:nth-child(2) {
  background-color: #f7f3f1;
}

.grid-item:nth-child(3) {
  background: #e0d8d9;
}

.grid-item:nth-child(4) {
  background: #f5d9ce;
}

.grid-item:nth-child(5) {
  background: #e1bcad;
}

.grid-item:nth-child(6) {
  background: rgba(231, 194, 192, 0.74);
}

.grid-item:nth-child(7) {
  background-color: #e7c2c0;
}

.grid-item:nth-child(8) {
  background: #eededf;
}

.grid-item:nth-child(9) {
  background: #d0c7cc;
}

.header-grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.header-grid-img {
  width: 1em;
  height: 1em;
  margin-left: auto;
  flex-shrink: 0;
}

.body-item-grid {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.item-section {
  background: #fff;
  display: inline;
  margin: 0.25em;
  padding: 0.5em 1em;
  font-size: 14px;
  position: relative;
  transition: all 0.5s ease;
  width: 100%;
}

.name-bcm {
  padding: 1em 1em 0;
  width: 400px;
  max-width: 20%;
}

.name-bcm input {
  width: 100%;
  padding: 3px 10px;
  font-weight: 700;
}

.guardar-bcm {
  padding-top: 0.5em;
  margin: -2em 1em 0em;
  text-align: right;
}

.btn-guardar-bcm {
  background: #e20515;
  box-shadow: 0px 2px 2px rgba(76, 175, 80, 0.2);
  border-radius: 3px;
  color: #fff;
  border: none;
  width: 100px;
  font-size: 12px;
  padding: 5px 0;
}

.add-text {
  margin-top: auto;
}

.add-text button {
  width: 32px;
  height: 32px;
  text-align: center;
  padding-top: 0 !important;
  font-size: 1.1em !important;
  color: white;
  background-color: #e20514b0;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: white !important;
}

.add-text button:hover{
  background-color: black;
}

.modal-head {
  display: flex;
  margin-bottom: 1em;
  align-items: flex-start;
  justify-content: space-between;
}

.modal-head span {
  font-weight: 700;
}

.header-modal-img {
  width: 1em;
  height: 1em;
  margin-left: 25px;
  flex-grow: 1;
}

.modal-desc {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #0f0f0f;
}

.modal-t_area {
  width: 100%;
  padding: 20px;
}

.t-border {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(118, 118, 118);
}

.modal-btns {
  text-align: right;
}

.btn-modal {
  background: #e20515;
  box-shadow: 0px 2px 2px rgba(76, 175, 80, 0.2);
  border-radius: 3px;
  color: #fff;
  border: none;
  width: 112px;
  padding: 10px 0;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-cancel {
  position: relative;
  top: -3em;
  right: -8em;
}

.modal-body {
  padding: 40px;
}

@media (min-width: 200px) {
  .main-grid {
    left: 14%;
    width: 100%;
  }

  .name-bcm {
    max-width: 50%;
  }
}

@media (min-width: 600px) {
  .main-grid {
    left: 10%;
    width: 100%;
    margin-right: 5%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item:last-child {
    grid-column: 1/3;
  }
  .name-bcm {
    max-width: 20%;
  }
}

@media (max-width: 1000px) {
  .add-text{
    align-self: flex-end;
  }
}

@media (min-width: 1000px) {

  .main-grid {
    left: 5%;
    width: 100%;
    margin-right: 5%;
  }
  .grid {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .grid-item:first-child {
    grid-column: 1/3;
    grid-row: 1/3;
  }

  .grid-item:nth-child(2) {
    grid-column: 3/5;
    grid-row: 1/2;
  }

  .grid-item:nth-child(3) {
    grid-column: 3/5;
    grid-row: 2/3;
  }

  .grid-item:nth-child(4) {
    grid-column: 5/7;
    grid-row: 1/3;
  }

  .grid-item:nth-child(5) {
    grid-column: 7/9;
    grid-row: 1/2;
  }

  .grid-item:nth-child(6) {
    grid-column: 7/9;
    grid-row: 2/3;
  }

  .grid-item:nth-child(7) {
    grid-column: 9/11;
    grid-row: 1/3;
  }

  .grid-item:nth-child(8) {
    grid-column: 1/6;
  }

  .grid-item:nth-child(9) {
    grid-column: 6/11;
  }
}
@media (min-width: 1450px) {
  .main-grid {
  left: 5%;
  width: 100%;
  margin-right: 5%;
  }
}
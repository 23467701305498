@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

.main {
  padding: 0%;
  margin: 0%;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  align-items: flex-start !important;
  position:  relative;

}

.flex {
  display: flex;
  align-items: center;
}

.modal-alert-content{
    position: relative;
    height: 250px;
    width: auto;
}

.modal-alert-text{
    text-align: center;
    font-weight: bolder;
    padding:1.4em;    
    padding-top: 30px !important;
}


.alert-btn-container{
    width: 100%;
    height: 14vh;
}

.btn-alert {
    background: #e20515;
    font-size: 10pt;
    box-shadow: 0px 2px 2px rgba(76, 175, 80, 0.2);
    border-radius: 3px;
    color: #fff;
    border: none;
    width: 120px;
    padding: 10px 0;
    border-radius: 0%;
  }

  .btn-alert:hover{
    background: #b60707;
  } 

  .btn-alert-cancel {
    background: #1a1a1a;
    font-size: 10pt;
    box-shadow: 0px 2px 2px rgba(76, 175, 80, 0.2);
    border-radius: 3px;
    color: #fff;
    border: none;
    width: 120px;
    padding: 10px 0;
    margin-top: 1.5em;
    border-radius: 0%;
  }

  .btn-alert-cancel:hover{
    background: #444242;
    
  } 



  @media (max-width: 500px){
    .modal-alert-content{
        align-self: center;
        width: 20em;
    }
  }

.PageLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 10%;
}

@media (max-width: 900px) {
  .PageLoading{
    width: 15%;
  }
}

@media (max-width: 600px) {
  .PageLoading{
    width: 20%;
  }
}


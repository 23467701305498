/*fonts*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");
/*font rules */
/* font-family: 'Montserrat', sans-serif;  */

* {
  padding: 0%;
  margin: 0%;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

.sidebar {
  background-color: #3c3c3c;
  color: #fff;
  height: calc(109vh - 62px);
  width: 250px;
  position: fixed;
  z-index: 1;
}

/*ipad pro*/
@media (max-width: 1024px) {
  .sidebar{
    height: 100%;
  }
}


/*Tablet*/
@media (max-width: 800px) {
  .sidebar{
    height: 100%;
  }
}

/*Phone*/
@media (max-width: 412px) {
  .sidebar{
    height: 100%;
  }
}





.sidebar .nav-link {
  padding-top: 22px;
  padding-bottom: 22px;
}

/*style onclick "active" */
.sidebar .nav-link .active {
  opacity: 1;
  background-color: #515151;
  border-left: 2px solid #e20515;
}

.sidebar .nav-link span {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #fff;
}
.logo-side{
  margin-right: .5em;
}
.nav-txt{
  padding-left: 0.5em;
}

.material-icons {
  color: #fff;
}

.sidebar-title {
  text-align: left;
  padding: 60px 10px 35px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
}
/*ipad pro*/
@media (max-width: 1024px) {
  .sidebar-title{
    text-align: center;
  }
}


.nav-side {
  height: 60%;
  justify-content: center;
}

.nav-side .nav-link:nth-child(2) {
  flex-grow: 1;
}

.btn-p {
  background: transparent;
  border: none;
  width: 15px;
  margin-left: 15px;
}

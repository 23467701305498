/*fonts*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");
/*font rules */
/* font-family: 'Montserrat', sans-serif;  */

* {
  padding: 0%;
  margin: 0%;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}
.navbar{
  position: relative;
  width: 100%;
  z-index: 999;
}
.navbar-p {
  background-color: #f3f2f2;
  font-size: 14px;
}

.profile-pic {
  overflow: hidden;
  border-radius: 100%;
  display: block;
  width: 30px;
  height: 30px;
  border: 2px double;
  border-color: #dfe0eb;
  margin-left: 10px;
}
.profile-pic img {
  position: relative;
  right: 27%;
  bottom: 20%;
  width: 3em;
  height: 3em;
}
.profile-name,
.navbar-nav .nav-item .nav-link {
  color: #252733;
}

.navbar-nav .nav-item .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.flex {
  display: flex;
  align-items: center;
}

.grow-sp {
  flex-grow: 1;
}

.dropdown-menu {
  background-color: #fff;
  border-radius: 0;
  text-align: center;

}

.dropdown-menu li a {
 font-size: 12pt;
  font-family: "Poppins";
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #575757;
  
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  .nav-link {
    text-align: center;
  }
  .user-drop {
    justify-content: center;
  }
  .profile-name-center {
    background-color: blue;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
